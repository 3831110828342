import React from "react";
import About from "sections/About/About";
import {useAboutData} from "lang/about";

const AboutPage = () => {
  const data = useAboutData();

  return <About data={data}/>;
};

export default AboutPage;
