import styled from "styled-components";
import { TitleL } from "components/Typography/Typography";

export const TitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  position: relative;
  padding: 124px 0; 
  margin-top: -1px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_big}) {
    padding: 104px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 96px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    padding: 82px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding: 72px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    padding: 56px 0;
  }
`;

export const Title = styled(TitleL)`
  text-align: center;
  line-height: 1.4;
  padding: 0 24px;
`;
