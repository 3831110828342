import { graphql, useStaticQuery } from "gatsby";

export const useAboutPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "image/about/about-company.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "image/about/about-offer.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "image/about/about-trust.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "image/about/about-help.png" }) {
        childImageSharp {
          fluid(maxHeight: 450, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
