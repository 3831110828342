import { useAboutPhotos } from "shared/Hooks/about/useAboutPhotos";
import React from "react";

export const useAboutData = () => {
  const photos = useAboutPhotos();
  return {
    metaInfo: {
      title: "O Nas",
      ogDescription:
        "Dostarczamy innowacyjne rozwiązania dla przemysłu. Jako dystrybutor firm Kennametal, Haimer oraz Walter w swojej ofercie posiadamy produkty tych renomowanych producentów, a nasze usługi doceniło już wielu przedsiębiorców. Sprawdź sam!",
    },
    hero: {
      title: "O Nas",
      subtitle:
        "Dostarczamy innowacyjne rozwiązania dla przemysłu. Jako dystrybutor firm Kennametal, Haimer oraz Walter w swojej ofercie posiadamy produkty tych renomowanych producentów, a nasze usługi doceniło już wielu przedsiębiorców. Sprawdź sam!",
    },
    ourCompany: {
      title: "Nasza firma",
      subtitle:
        "Wyróżnia nas młody, dynamiczny zespół i ciągła chęć rozwoju, dzięki czemu dążymy do tego, by zawsze dostarczać innowacyjne rozwiązania i narzędzia naszym klientom. Obsługujemy przemysł, oferując pomoc we wdrożeniach, doborze narzędzi i opracowywaniu technologii. Dostarczamy również rozwiązania wspomagające zarządzanie narzędziami i gospodarką narzędziową.",
      src: photos.img1,
      bigImg: true,
    },
    ourOffer: {
      title: "Nasza oferta",
      subtitle:
        "Będąc oficjalnym dystrybutorem firm Kennametal, Haimer, Walter, MA Ford i YG1 mamy dostęp do całej oferty w/w producentów. Ich narzędzia obrotowe charakteryzują się wysoką jakością wykonania, precyzją działania i wytrzymałością. Z kolei posiadane w naszym asortymencie automaty ToolBOSS znacznie ułatwiają automatyzację pracy, pozwalając zaoszczędzić na wydatkach skierowanych na administrację czy nowe narzędzia.",
      src: photos.img2,
      bigImg: true,
    },
    trust: {
      title: "Profesjonalizm i zaufanie",
      subtitle:
        "Jesteśmy rzetelnym partnerem, zawsze starając się jak najlepiej zrozumieć potrzeby naszych klientów i spełniać ich oczekiwania. Nasza firma rozwija się dynamicznie, zdobywając zaufanie kolejnych partnerów biznesowych i ciesząc się szacunkiem. Jesteśmy nastawieni na ciągły rozwój, aby móc dostarczać jak najlepsze rozwiązania dla branży przemysłu. Przychodzimy z pomocą na każdym etapie realizacji umowy, służąc radą i pomocą od negocjacji po finalizację kontraktu.",
      src: photos.img3,
      bigImg: true,
    },
    weHelp: {
      title: "Wsparcie dla biznesu",
      subtitle:
        "Pomagamy zarówno na etapie wyboru, jak i z samym opracowywaniem technologii, a także przy ich wdrażaniu. Stale podnosimy nasze kompetencje, by oferowane przez nas doradztwo stało na najwyższym poziomie. Jesteśmy profesjonalistami w swojej dziedzinie i zdając się na nas, masz gwarancję, że otrzymasz fachową pomoc, doradztwo i obsługę. Specjalizujemy się w obsłudze każdego rodzaju przemysłu, dostarczając najlepsze rozwiązania dla branży obróbki metali.",
      src: photos.img4,
      bigImg: true,
    },
    bottomSection: {
      title: (
        <>
          Nasza firma to pewny partner,
          <br /> a współpraca z nami przyspieszy rozwój Twojego biznesu.
        </>
      ),
    },
  };
};
