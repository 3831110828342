import React, {useEffect} from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";
import {useDispatch} from "react-redux";
import {heroToggle} from "../../store/menuColor";
import {Title, TitleWrapper} from "./styles";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const About = ({data}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(heroToggle(false)), 500)
  }, [dispatch])
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="yellow" reverse/>
      <SectionsWrappper>
        <Section data={data.ourCompany}/>
        <Section data={data.ourOffer} reverse/>
        <Section data={data.trust}/>
        <Section data={data.weHelp} reverse/>
      </SectionsWrappper>
      <TitleWrapper>
        <Title>{data.bottomSection.title}</Title>
      </TitleWrapper>
    </>
  );
};

export default About;
